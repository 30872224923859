const URLS = {
	HML: 'https://doacao.app.br:443/doacao/',
    LOCAL: 'http://localhost:8443/doacao/',
};

const server = {
    url: URLS.HML
}

export default server;
