import styled from "styled-components";

const BaseModalStyled = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    
`

export { BaseModalStyled }