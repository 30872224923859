import styled from 'styled-components';
import { BaseScreen, } from '../../components';

export const HomeAdminScreen = styled(BaseScreen)`
    /* padding: 0; */
    /* height: 100vh; */
    align-items: flex-start;
`


export const HomeAdminContent = styled.div`
    height: 100%;
`
